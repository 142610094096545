
































































import { CommentModel } from '@/models/post-model'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    avatar: () => import('@/modules/community/common/components/avatar.vue'),
  },
})
export default class CommentItem extends Vue {
  @Prop() comment?: CommentModel
}
